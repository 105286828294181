import * as React from "react"
import {technologies} from "../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const runCallback = (cb) => {
    return cb();
};

export default class Paginator extends React.Component {

    render() {
        return <div className={'paginator'}>
            <ul>

                <li><AniLink fade duration={0.1} hex="#000000" to={technologies[this.props.prev-1].url}><div className={'button paginator-left'}><div className={'title'}>{technologies[this.props.prev-1].text}</div></div></AniLink></li>
                {technologies.map((submenu, index) => (
                    <li key={index} className={index === this.props.current-1 ? 'dot active' : 'dot'}/>
                ))}
                <li><AniLink fade duration={0.1} hex="#000000" to={technologies[this.props.next-1].url}><div className={'button paginator-right'}><div className={'title'}>{technologies[this.props.next-1].text}</div></div></AniLink></li>
            </ul>
            <div className={'clear'}/>
        </div>
    }
}
